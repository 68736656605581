import React, {useContext, useState} from 'react';
import {AppContext} from "../../context/AppContext";
import dayjs from "dayjs";
import favIcon from "../../assets/icons/fav-on.svg";
import favOff from "../../assets/icons/fav-off.svg";
import StatsRow from "../stats/StatsRow";
import StatsRowNoInfo from "../stats/StatsRowNoInfo";
import LiveScoresMatchStatsRow from "./LiveScoresMatchStatsRow";
import {saveMatchToLocalStorage} from "../local-storage/ManageLocalStorage";

function LiveScoresMatches(props) {

    const {addAndRemoveFavLiveScoresMatches, isFavLiveScores, lang, i18String} = useContext(AppContext);
    const {matches, league} = props;

    const startTime = (value) => {
        return dayjs(value).format("HH:mm")
    }

    const statStyleWidth = () => {
        return 'live-scores-stat-style-width-other'
    }

    return (
        <div>
            {
                matches.map((match) => {
                    return (
                        <div key={match.id}>
                            {/*WEB*/}
                            <div className="live-scores-matches-container">
                                <table className="live-scores-table-section">
                                    <tbody>
                                    <tr className="live-scores-table-row">
                                        <td className="match-info-td">
                                            <div className="live-scores-match-info-container">
                                                <div>
                                                    <div>
                                                        {
                                                            isFavLiveScores(match.id) ?
                                                                <img
                                                                    onClick={() => addAndRemoveFavLiveScoresMatches(league, match)}
                                                                    className="fav-icon" src={favIcon} alt="fav"/> :
                                                                <img
                                                                    onClick={() => addAndRemoveFavLiveScoresMatches(league, match)}
                                                                    className="fav-icon" src={favOff} alt="fav"/>
                                                        }
                                                    </div>
                                                </div>

                                                <div className='live-scores-texts-12px'>
                                                    {match.status === 'Fixture' &&
                                                        <span>{startTime(match.dateTimeUtc)}</span>}
                                                    {match.status === 'Played' && <span>MS</span>}
                                                    {match.h2 === true && <span className="live-match-min">{i18String('HT')}</span>}
                                                    {match.status === 'Postponed' &&
                                                        <div>
                                                            {
                                                                lang === 'en' ?
                                                                    <span className="live-match-min">Post.</span>
                                                                    :
                                                                    <span className="live-match-min">Ert.</span>
                                                            }
                                                        </div>
                                                    }
                                                    {match.status === 'Playing' && match.st !== 'HT' &&
                                                        <span className="live-match-min">{match.min}'</span>}
                                                </div>

                                                <div className="card-container">
                                                    {match?.hred > 0 &&
                                                        <div className="match-card red-card">{match.hred}</div>}
                                                    {match?.hyellow > 0 &&
                                                        <div className="match-card yellow-card">{match.hyellow}</div>}
                                                </div>

                                                <span
                                                    onClick={() => {
                                                        window.open(`/team-detail/${match.homeId}`)
                                                    }}
                                                    className="live-scores-match-team-names">
                                                    {match.home}
                                                </span>
                                                <div
                                                    className="score-container live-scores-texts-12px"
                                                    onClick={() => saveMatchToLocalStorage(`teams-state:${match.id}`, {
                                                        matchId: match.id,
                                                        home: match.home,
                                                        homeId: match.homeId,
                                                        away: match.away,
                                                        awayId: match.awayId,
                                                        time: match.dateTimeUtc,
                                                        seasonId: match.seasonId
                                                    })}
                                                >
                                                    {match.status === 'Playing' &&
                                                        <span><span
                                                            className="live-match-score">{match.hscore} -</span>  <span
                                                            className="live-match-score">{match.ascore}</span></span>}
                                                    {match.status === 'Played' &&
                                                        <span
                                                            style={{
                                                                color: 'black',
                                                                fontWeight: '600'
                                                            }}>{match.hscore} - {match.ascore}</span>}
                                                    {match.status === 'Fixture' &&
                                                        <span style={{color: 'black', fontSize: '.8rem'}}>-</span>}
                                                </div>
                                                <span
                                                    onClick={() => {
                                                        window.open(`/team-detail/${match.awayId}`)
                                                    }}
                                                    className="live-scores-match-team-names live-scores-away-team-name">{match.away}</span>

                                                <div className="card-container">
                                                    {match?.ayellow > 0 &&
                                                        <div className="match-card yellow-card">{match.ayellow}</div>}
                                                    {match?.ared > 0 &&
                                                        <div className="match-card red-card">{match.ared}</div>}
                                                </div>
                                            </div>
                                        </td>
                                        {/*MATCH STATS ROW*/}
                                        {/*CORNER*/}
                                        <td className={statStyleWidth()}>
                                            <div style={{ display: 'flex', justifyContent: 'center' }}>
                                                {
                                                    (match.status === 'Played' || match.status === 'Playing') && (
                                                        <span className='live-scores-1-half'>{match.hcorner} - {match.acorner}</span>
                                                    )
                                                }
                                            </div>
                                        </td>
                                        {/*IY*/}
                                        <td className={statStyleWidth()}>
                                            <div style={{display: 'flex', justifyContent: 'center'}}>
                                                {
                                                    match.h2 === true &&
                                                    <span className='live-scores-1-half'>{match.hp1} - {match.ap1}</span>
                                                }
                                                {/*<span className={'stats-type-mobile'}>{match.hp1} - {match.ap1}</span>*/}
                                            </div>
                                        </td>
                                        {/*K*/}
                                        <td className={statStyleWidth()}>
                                            <div style={{display: 'flex', justifyContent: 'center'}}>

                                            </div>
                                        </td>
                                        {/*FT ODDS*/}
                                        {match?.ft?.odds ? (
                                            match.ft.odds.map(odd => (
                                                <td key={odd.name} className={statStyleWidth()}>
                                                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                                                        <span className='live-scores-odds'>{odd.value}</span>
                                                    </div>
                                                </td>
                                            ))
                                        ) : (
                                            Array(3).fill().map((_, index) => (
                                                <td key={index} className={statStyleWidth()}>
                                                    <div style={{ display: 'flex', justifyContent: 'center' }} />
                                                </td>
                                            ))
                                        )}

                                        {/*UO ODDS*/}
                                        {match?.uo?.odds ? (
                                            match.uo.odds.map(odd => (
                                                <td key={odd.name} className={statStyleWidth()}>
                                                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                                                        <span className='live-scores-odds'>{odd.value}</span>
                                                    </div>
                                                </td>
                                            ))
                                        ) : (
                                            Array(2).fill().map((_, index) => (
                                                <td key={index} className={statStyleWidth()}>
                                                    <div style={{ display: 'flex', justifyContent: 'center' }} />
                                                </td>
                                            ))
                                        )}

                                    </tr>
                                    </tbody>
                                </table>
                            </div>

                            {/*MOBILE*/}
                            {/*<div className="live-scores-section-mobile match-team-names">
                                <table className="live-scores-table-section">
                                    <tbody>
                                    <tr className="live-scores-table-row">
                                        <td className="match-info-td">
                                            <div className="live-scores-match-info-container">
                                                <div>
                                                    {match.status !== 'Played' &&
                                                        <div>
                                                            {
                                                                isFav(match.id) ?
                                                                    <img
                                                                        onClick={() => addAndRemoveFavMatches(league, match)}
                                                                        className="fav-icon" src={favIcon} alt="fav"/> :
                                                                    <img
                                                                        onClick={() => addAndRemoveFavMatches(league, match)}
                                                                        className="fav-icon" src={favOff} alt="fav"/>
                                                            }
                                                        </div>
                                                    }
                                                </div>

                                                <div>
                                                    {match.status === 'Fixture' &&
                                                        <span>{startTime(match.dateTimeUtc)}</span>}
                                                    {match.status === 'Played' && <span>MS</span>}
                                                    {match.status === 'Playing' && !match.status !== 'Half Time' &&
                                                        <span className="live-match-min">{match.min}'</span>}
                                                    {match.halfTime && <span>İY</span>}
                                                </div>

                                                <div className="card-container">
                                                    {match.hred > 0 &&
                                                        <div className="match-card red-card">{match.hred}</div>}
                                                </div>

                                                <span className="match-team-name">{match.home}</span>
                                                <div className="score-container" to={`/match-detail/${match.id}`}
                                                     onClick={() => {
                                                         localStorage.setItem("teams-state", JSON.stringify(
                                                             {
                                                                 home: match.home,
                                                                 homeId: match.homeId,
                                                                 away: match.away,
                                                                 awayId: match.awayId,
                                                                 time: match.dateTimeUtc
                                                             }
                                                         ))
                                                         window.open(`/match-detail/${match.id}`)
                                                     }}>
                                                    {match.status === 'Playing' &&
                                                        <span><span
                                                            style={{color: '#c00'}}>{match.hscore} -</span>  <span
                                                            style={{color: '#c00'}}>{match.ascore}</span></span>}
                                                    {match.status === 'Played' &&
                                                        <span
                                                            style={{color: 'black'}}>{match.hscore} - {match.ascore}</span>}
                                                    {match.status === 'Fixture' &&
                                                        <span style={{color: 'black', fontSize: '.8rem'}}>-</span>}
                                                </div>
                                                <span className="match-team-name away-team-name">{match.away}</span>

                                                <div className="card-container">
                                                    {match.ared > 0 &&
                                                        <div className="match-card red-card">{match.ared}</div>}
                                                </div>
                                                <div></div>
                                                <div></div>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr style={{display: 'flex'}}>
                                        {
                                            match[statsType] ?
                                                match[statsType].map((stat) => {
                                                    return (
                                                        <StatsRow key={stat.name} borderStyle={statStyle(stat)}
                                                                  stat={stat}
                                                                  statsType={statsType}/>
                                                    )
                                                }) :
                                                <StatsRowNoInfo borderStyle={'no-info-stats'}/>
                                        }
                                    </tr>
                                    </tbody>
                                </table>
                            </div>*/}
                        </div>
                    )
                })
            }
        </div>
);
}

export default LiveScoresMatches;
