import React from 'react';
import cornerFlag from '../../assets/icons/corner-flag.png';

function LiveScoresTableHeader(props) {
    const statStyleWidth = () => {
        if (props.currentStats === 'avgStatsHeader') {
            return 'stats-header-red'
        } else {
            return 'live-scores-stats-header-other'
        }
    }

    return (
        <td className={statStyleWidth()}>
            <div className='live-scores-table-header-container'>
                {
                    props.stHeader.text ? (
                        <span className="general-11px-font-style">{props.stHeader.text}</span>
                    ) : (
                        <span className="empty-cell"></span>
                    )
                }

                {
                    props.matches.some((match) => {
                        return match.status === 'Playing' || match.status === 'Played';
                    }) && props.stHeader?.isIcon &&
                    <div className='stats-half-time-scores-container'>
                        <img src={cornerFlag} alt="Corner" style={{ width: '13px', height: '13px' }} />
                    </div>
                }
            </div>
        </td>
    );
}

export default LiveScoresTableHeader;
