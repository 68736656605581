import React, {useContext, useState} from 'react';
import crossIcon from '../../../assets/icons/cross-grey.png';
import {AppContext} from "../../../context/AppContext";

function PopupPremium(props) {
    const {i18String} = useContext(AppContext);
    const {setIsShownPopup} = props;

    const goJoinPremium = () => {
        window.open('/join-premium');
        document.body.style.overflow = 'auto';
        setIsShownPopup(false);
    };

    return (
        <>
            <div className='popup-style'>
                <div className='jp-popup-content-style'>
                    <div className='jp-close-button-container' onClick={() => setIsShownPopup(false)}>
                        <img src={crossIcon} alt="Close" className='jp-close-popup'/>
                    </div>
                    <div>
                        <h2 className='jp-warning-popup-title'>{i18String('Join Premium')}</h2>
                        <p className='jp-popup-warning-text'>
                            {i18String('Futbol Verileri is an analytics platform offering detailed football statistics, live scores, match results, goal/corner/card tables, fixtures, betting schedule and more for 2000+ leagues.')}
                        </p>

                        <button className='jp-go-join-premium-button' onClick={goJoinPremium}>{i18String('Join Premium')}</button>
                    </div>
                </div>
            </div>
        </>
    );
}

export default PopupPremium;
