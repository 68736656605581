import React from 'react';
import './Digital.css';
import DigitalHeader from "./header/DigitalHeader";
import DigitalMenu from "./menu/DigitalMenu";
import DigitalContent from "./content/DigitalContent";
import Footer from "../footer/Footer";
import DigitalFooter from "./footer/DigitalFooter";

function Digital(props) {
    return (
        <div className='general-digital-section'>
            <DigitalHeader />
            <DigitalMenu />
            <DigitalContent />
            <DigitalFooter />
        </div>
    );
}

export default Digital;
